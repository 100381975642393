export const lightTheme = {
  mode: { now: "light" },
  colors: {
    colorMain: "",
    colorBg: "var(--Grayscale-20)",
    colorMainFont: "var(--Grayscale-60)",
    colorCard: "var(--Grayscale-10)",
    colorQuestion: "var(--Brown-10)",
    colorFeedCard: "var(--Grayscale-10)",
    colorModal: "var(--Grayscale-10)",
    colorBorder: "var(--Grayscale-60)",
    colorBadge: "var(--Grayscale-10)",
    colorInput: "var(--Grayscale-20)",
    colorInputField: "var(--Grayscale-20)",
    colorTextButton: "var(--Brown-40)",
    colorBrownButton: "var(--Brown-40)",
    colorBrownActiveButton: "var(--Brown-50)",
    colorBrownDisableButton: "var(--Brown-30)",
    colorBrownOutlineActiveButton: "var(--Brown-20)",
    colorBrownOutlineButton: "var(--Brown-10)",
    colorDisable: "var(--Brown-20)",
    colorPagination: "var(--Brown-40)",
    colorGray_10: "var(--Grayscale-10)",
    colorGray_20: "var(--Grayscale-20)",
    colorGray_40: "var(--Grayscale-40)",
    colorGray_60: "var(--Grayscale-60)",
    colorBrown_10: "var(--Brown-10)",
    colorBrown_20: "var(--Brown-20)",
    colorBrown_40: "var(--Brown-40)",
    colorRed: "var(--Red-50)",
  },
};

export const darkTheme = {
  mode: { now: "dark" },
  colors: {
    colorMain: "",
    colorBg: "hsl(0, 0%, 2.4%)",
    colorMainFont: "#FAFAFA",
    colorBorder: "hsl(0, 0%, 100%)",
    colorCard: "hsl(0, 0%, 10%)",
    colorQuestion: "hsl(0, 0%, 10%)",
    colorFeedCard: "hsl(0, 0%, 15%)",
    colorModal: "hsl(0, 0%, 10%)",
    colorBadge: "hsl(0, 0%, 17%)",
    colorInput: "hsl(0, 0%, 20%)",
    colorInputField: "hsl(0, 0%, 40%)",
    colorTextButton: "hsl(0, 0%, 100%)",
    colorBrownButton: "hsl(0, 0%, 35%)",
    colorBrownActiveButton: "hsl(0, 0%, 15%)",
    colorBrownDisableButton: "hsl(0, 0%, 5%)",
    colorBrownOutlineActiveButton: "hsl(0, 0%, 15%)",
    colorBrownOutlineButton: "hsl(0, 0%, 20%)",
    colorDisable: "hsl(0, 0%, 30%)",
    colorPagination: "var(--Blue-50)",
    colorGray_10: "hsl(0, 0%, 0%)",
    colorGray_20: "hsl(0, 0%, 2.4%)",
    colorGray_40: "hsl(0, 0%, 49.4%)",
    colorGray_60: "hsl(0, 0%, 100%)",
    colorBrown_10: "hsl(0, 0%, 3.9%)",
    colorBrown_20: "hsl(0, 0%, 10.6%)",
    colorBrown_40: "hsl(0, 0%, 67.1%)",
    colorRed: "hsl(0, 0%, 27.5%)",
  },
};
